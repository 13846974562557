import { Inject, inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
	Auth,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
	UserCredential,
	OAuthProvider,
} from "@angular/fire/auth";
import { from } from "rxjs/internal/observable/from";
import { IUserRegistrationCycle } from "@sportyano/core/models/authentications/register-data.model";
import { Observable } from "rxjs/internal/Observable";
import {
	ISocialCheckMailResponse,
	SOCIAL_PROVIDER,
	SocialLogin,
	SocialProvider,
	ISocialUserRequest,
} from "@sportyano/core/models/authentications/social-login-data.model";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { STORAGE } from "@sportyano/core/models/storage/storage.const";
import { setCookie } from "@sportyano/core/common/utils/cookies-controller";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import {
	USER_TYPE,
	UserType,
} from "@sportyano/core/models/user-type/user-type";
import { of, tap } from "rxjs";
import { API_RESPONSE_CODE } from "@sportyano/core/models/response/response.const";

@Injectable({
	providedIn: "root",
})
export class SocialMediaAuthenticationService {
	private _authService = inject(AuthService);
	private _httpClient = inject(HttpClient);
	private _router: Router = inject(Router);
	private _socialUserProviderPayload: ISocialUserRequest | null = null;
	private readonly apiUrl: string = environment.baseUrl;

	constructor(@Inject(PLATFORM_ID) private platform: object) {}

	public set setSocialUserProviderPayload(payload: ISocialUserRequest) {
		this._socialUserProviderPayload = payload;
	}

	public get getSocialUserProviderPayload(): ISocialUserRequest | null {
		return this._socialUserProviderPayload;
	}

	public handleGoogleSignInPayload(
		result: any,
		userType: UserType | null = null,
	): ISocialUserRequest {
		const providerData = result.user.providerData[0];
		const requestPayload = {
			provider: providerData.providerId as string,
			provider_id: providerData.uid,
			name: providerData.displayName as string,
			email: providerData.email as string,
			type: userType,
			token: result?.user["accessToken"] as string,
		};

		this.setSocialUserProviderPayload = requestPayload;
		return requestPayload;
	}

	public storeUserData(response: ISocialCheckMailResponse): void {
		setCookie(STORAGE.token, response.data.token || "");
		localStorage.setItem(STORAGE.userType, response?.data.userType || "");
		localStorage.setItem(
			STORAGE.userData,
			JSON.stringify(response.data.user || {}),
		);
	}

	public setRegisterSession(providerData: ISocialUserRequest): void {
		this._authService.setRegisterSessionCycle(
			"provider_id",
			providerData.provider_id,
		);
		this._authService.setRegisterSessionCycle(
			"provider",
			providerData.provider,
		);
		this._authService.setRegisterSessionCycle(
			"socialUserName",
			providerData.name,
		);
		this._authService.setRegisterSessionCycle(
			"socialUserEmail",
			providerData.email,
		);
	}

	public setSocialUserData(payload: {
		loggedUser: ISocialCheckMailResponse;
		socialUser: ISocialUserRequest;
	}) {
		this.storeUserData(payload.loggedUser as any);
		this.setRegisterSession(payload.socialUser);
		this._authService.setAuthenticationState(true);

		const redirectUrl = sessionStorage.getItem("redirectAfterLogin");
		const currentUrl = this._router.url as string;
		if (redirectUrl) {
			sessionStorage.removeItem("redirectAfterLogin");
			const url = new URL(redirectUrl, window.location.origin); // Parse URL
			const queryParams = Object.fromEntries(url.searchParams.entries()); // Convert query params to object
			this._router.navigate([url.pathname], { queryParams });
		} else if (currentUrl === "/auth/login") {
			// If logging in from login component
			this._router.navigate(["/main"]);
		} else if (currentUrl === "/auth/register/registeration-form") { 
			this.handleNavigate(payload.socialUser.type);
		} else {
			// If logging from login popup
			this._authService.setLoginPopupState(false);
		}
	}

	socialMediaCheckEmail(
		payload: ISocialUserRequest,
	): Observable<ISocialCheckMailResponse> {
		return this._httpClient
			.post<ISocialCheckMailResponse>(
				this.apiUrl + "auth/social/check",
				payload,
			)
			.pipe(tap((response) => {}));
	}

	socialMediaSignIn(payload: ISocialUserRequest): Observable<SocialLogin> {
		return this._httpClient.post<SocialLogin>(
			this.apiUrl + "auth/social/login",
			payload,
		);
	}

	private handleNavigate(userType: any) {
		switch (userType) {
			case USER_TYPE.academy:
			case USER_TYPE.playground:
				this._router.navigateByUrl(
					"/main/account/branches-management/add-new-branch",
				);
				break;
			case USER_TYPE.player:
				this._router.navigateByUrl(
					"/main/account/profile-management/player-info",
				);
				break;
			case USER_TYPE.viewer:
				this._router.navigateByUrl(
					"/main/account/profile-management/viewer-info",
				);
				break;
			default:
				this._router.navigateByUrl(
					"/main/account/account-management/account-info",
				);
				break;
		}
	}
}
